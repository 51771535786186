<template>
  <LayoutNew>
    <h1 class="text-center">Manage Asset Label</h1>
    <MDBCard id="UploadFile" class="mb-2">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">Assigned Users</h4>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBTable striped hover class="align-middle">
          <thead class="table-dark">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                {{ item.header }}
              </th>
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr v-for="(item, i) in assignedUsersData.assignedUsers" :key="i">
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.email }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="assignedUsersData.assignedUsers.length > 0">
            <tr v-for="(item, i) in assignedUsersData.assignedUsers" :key="i">
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.email }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">
                <div style="text-align: center">
                  <p>There are no assigned users.</p>
                </div>
              </td>
            </tr>
          </tbody>
        </MDBTable>
        <!-- toast -->
        <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right"
          appendToBody stacking width="350px" color="danger" text="white">
          <template #title>
            {{ toastMessage }}
          </template>
        </MDBToast>
      </MDBCardBody>
    </MDBCard>
  </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTable,
  MDBToast,
} from "mdb-vue-ui-kit";
import { useRoute } from "vue-router";
import { computed, onBeforeMount, ref } from "vue";
import axios from "axios";

const route = useRoute();
const assetLabelId = route.params.id;
const isLoading = ref(false);

const tableHeaders = computed(() => [
  {
    header: "Name",
  },
  {
    header: "Email",
  },
]);

const assignedUsersData = ref({
  assignedUsers: [],
  id: "",
  name: "",
  partnerPercentage: "",
});

const toastError = ref(false);
const toastMessage = ref("");

onBeforeMount(async () => {
  isLoading.value = true;
  await axios
    .get("api/assetlabels/" + assetLabelId)
    .then(function (response) {
      isLoading.value = false;
      assignedUsersData.value = response.data;
    })
    .catch(function (error) {
      isLoading.value = false;
      toastError.value = true;
      toastMessage.value = "An error occurred during request" + error;
    });
});

import { useTitle } from "@vueuse/core";
useTitle("Manage Asset Label | CreatorShield");
</script>

<style scoped></style>
